import React from "react"
import { graphql } from "gatsby"
import Layout from "~components/layout"
import SEO from "~components/seo"
import RenderModule from "~components/renderModule"
import resolveLink from '~utils/resolveLink'

const PageTemplate = ({ data }) => {
  const modules = data.sanityPage.modules
  return(
    <Layout>
      <SEO metaInfo={data.sanityPage._rawMeta} pagePath={resolveLink(data.sanityPage)}/>
      {modules.map(m => (
        <RenderModule key={m._key} type={m._type} m={m} />
      ))}
    </Layout>
  )
}

export const query = graphql`
query($id: String!) {
  sanityPage(id: {eq: $id}) {
    _rawMeta(resolveReferences: {maxDepth: 2})
    title
    _type
    slug {
      current
    }
    modules {
      ... on SanityRichText {
          _key
          _type
          _rawBody
        }
      ... on SanityFaqs {
          _key
          _type
          title
          questions {
            _rawAnswer
            question
            _key
          }
        }
      ... on SanityTile {
          _key
          _type
          _rawText(resolveReferences: {maxDepth: 4})
          image {
            asset {
              _id
              url
              fluid {
                aspectRatio
                sizes
                src
                srcSet
                srcSetWebp
                srcWebp
              }
            }
          }
          cta {
            ... on SanityInternalLink {
              _key
              _type
              _rawTo(resolveReferences: {maxDepth: 6})
              linkText
            }
            ... on SanityExternalLink {
              _key
              _type
              url
              linkText
            }
          }
          order
          title
        }
      ... on SanityHeadingText {
          _key
          _type
          _rawText
          title
          center
        }
      ... on SanityTextIllo {
          _key
          _type
          _rawCta(resolveReferences: {maxDepth: 8})
          backgroundType
          _rawHeading
          illo {
            asset {
              fluid {
                aspectRatio
                sizes
                src
                srcSet
                srcSetWebp
                srcWebp
              }
            }
          }
        }
      ... on SanityProductSlider {
          _key
          _type
          slides {
            image {
              asset {
                fluid {
                  aspectRatio
                  base64
                  sizes
                  src
                  srcSet
                  srcSetWebp
                  srcWebp
                }
              }
            }
            _rawLink(resolveReferences: {maxDepth: 4})
            subtitle
            text
            title
            buttonText
          }
          title
        }
      ... on SanityReviewsGrid {
        _key
        _type
        reviews {
          link
          name
          platform
          rating
          username
          _rawReview
          image {
            asset {
              fluid {
                aspectRatio
                base64
                sizes
                src
                srcSet
                srcSetWebp
                srcWebp
              }
            }
          }
        }
        subtitle
        title
      }
      ... on SanityGroupGrid {
        _key
        _type
        title
        bg: background
        groups {
          group {
            slug {
              current
            }
            _type
          }
          buttonText
          subtitle
          title
          image {
            asset {
              fluid {
                srcWebp
                srcSetWebp
                srcSet
                src
                sizes
                base64
                aspectRatio
              }
            }
          }
        }
      }
      ... on SanityDivider {
          _key
          _type
          width
        }
      ... on SanityIconRow {
        _key
        _type
        icons {
          text
          title
          image {
            asset {
              fluid {
                aspectRatio
                sizes
                src
                srcSet
                srcSetWebp
                srcWebp
              }
            }
          }
        }
      }
      ... on SanityCenteredText {
        _key
        _rawText
        _type
        title
        image {
          asset {
            fluid {
              aspectRatio
              sizes
              src
              srcSet
              srcSetWebp
              srcWebp
            }
          }
        }
      }
      ... on SanityTicker {
        _key
        _type
        highlightColor {
          hex
        }
        _rawText(resolveReferences: {maxDepth: 10})
        background {
          hex
        }
        textColor {
          hex
        }
        _rawLink(resolveReferences: {maxDepth: 6})
      }
      ... on SanityFullWidth {
        _key
        _type
        textMode
        bgImage {
          asset {
            fluid {
              aspectRatio
              base64
              sizes
              src
              srcSet
              srcSetWebp
              srcWebp
            }
          }
        }
        bgImagePortrait {
          asset {
            fluid {
              aspectRatio
              base64
              sizes
              src
              srcSet
              srcSetWebp
              srcWebp
            }
          }
        }
        bgVideoLandscape {
          asset {
            url
          }
        }
        bgVideoPortrait {
          asset {
            url
          }
        }
        buttonColor
        buttonText
        buttonType
        subtitle
        title
      }
      ... on SanityTextImage {
        _key
        _type
        imageFit
        textBackground
        textAlign
        order
        _rawText(resolveReferences: {maxDepth: 10})
        imageBackground {
          hex
        }
        video {
          asset {
            url
          }
        }
        image {
          _key
          asset {
            _id
            url
            fluid(maxWidth: 2400) {
              srcWebp
              srcSetWebp
              srcSet
              src
              sizes
              base64
              aspectRatio
            }
          }
        }
      }
      ... on SanityContactForm {
        _key
        _type
        formName
        _rawText(resolveReferences: {maxDepth: 10})
      }
      ... on SanityVideoEmbed {
        _key
        _type
        url
        # caption
        # placeholderImage {
        #   _key
        #   asset {
        #     _id
        #     url
        #     fluid(maxWidth: 2400) {
        #       srcWebp
        #       srcSetWebp
        #       srcSet
        #       src
        #       sizes
        #       base64
        #       aspectRatio
        #     }
        #   }
        # }
      }
    }
  }
}
`

export default PageTemplate
